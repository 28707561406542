import { fn } from '../../common/api/functions'

export const searchReindexAll = fn('searchReindexAll')
export const importUsersFromAzure = fn<{ validation: boolean }, any>(
  'importUsersFromAzure'
)
export const recomputeCircleParticipantCache = fn(
  'recomputeCircleParticipantCache'
)
export const replaceOldIds = fn<{ text: string }, string>('replaceOldIds')
