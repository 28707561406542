import {
  Box,
  Button,
  Card,
  CardBody,
  Container,
  Heading,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  StackDivider,
  Text,
  UnorderedList,
  VStack,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  recomputeCircleParticipantCache,
  importUsersFromAzure,
  searchReindexAll,
} from '../../user/api/user_functions'
import Loading from '../atoms/Loading'
import { WarningIcon } from '@chakra-ui/icons'

export default function SuperAdminPage() {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [updateUsersLoading, setUpdateUsersLoading] = useState(false)
  const [validationModal, setValidationModal] = useState(false)
  const [data, setData] = useState<{ usersToAdd: any[]; usersToDelete: any[] }>(
    { usersToAdd: [], usersToDelete: [] }
  )
  // Reindex all entities in search engine
  const handleSearchReindex = async () => {
    setLoading(true)
    await searchReindexAll({})
    setLoading(false)
  }

  // Recompute circle_participant_cache
  const handleRecomputeCircleParticipantCache = async () => {
    setLoading(true)
    await recomputeCircleParticipantCache({})
    setLoading(false)
  }
  const handleGetUsersToUpdate = async () => {
    setUpdateUsersLoading(true)
    setData(await importUsersFromAzure({ validation: false }))
    setValidationModal(true)
    setUpdateUsersLoading(false)
  }
  const handleUpdateUsers = async () => {
    setValidationModal(false)
    setUpdateUsersLoading(true)
    await importUsersFromAzure({ validation: true })
    setUpdateUsersLoading(false)
  }
  return (
    <Container maxW="md" mt="60px">
      {loading && <Loading active center />}

      <Heading size="md" mb={10}>
        {t('SuperAdminPage.heading')}
      </Heading>

      <VStack mb={10} align="start">
        <Button
          size="sm"
          colorScheme="orange"
          isDisabled={loading}
          onClick={handleSearchReindex}
        >
          {t('SuperAdminPage.searchReindex')}
        </Button>

        <Button
          size="sm"
          colorScheme="orange"
          isDisabled={loading}
          onClick={handleRecomputeCircleParticipantCache}
        >
          {t('SuperAdminPage.recomputeCircleParticipantCache')}
        </Button>
        <Button
          size="sm"
          leftIcon={<WarningIcon />}
          isLoading={updateUsersLoading}
          onClick={handleGetUsersToUpdate}
        >
          {t('SuperAdminPage.updateUsers')}
        </Button>
      </VStack>

      <Modal isOpen={validationModal} onClose={() => setValidationModal(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('SuperAdminPage.confirmEditUsers')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Card>
              <CardBody>
                <Stack divider={<StackDivider />} spacing="4">
                  <Box>
                    <Heading size="xs" textTransform="uppercase">
                      {t('SuperAdminPage.usersToAdd')}
                    </Heading>
                    {data.usersToAdd.length > 0 ? (
                      <UnorderedList key="list-add">
                        {data.usersToAdd.map((user) => (
                          <ListItem key={user.id}>
                            {user.displayName} - {user.email}
                          </ListItem>
                        ))}
                      </UnorderedList>
                    ) : (
                      <Text pt="2" fontSize="sm">
                        {t('SuperAdminPage.noUserToAdd')}
                      </Text>
                    )}
                  </Box>
                  <Box>
                    <Heading size="xs" textTransform="uppercase">
                      {t('SuperAdminPage.usersToDelete')}
                    </Heading>
                    {data.usersToDelete.length > 0 ? (
                      <UnorderedList key="list-delete">
                        {data.usersToDelete.map((user) => (
                          <ListItem key={user.id}>
                            {user.displayName} - {user.email}
                          </ListItem>
                        ))}
                      </UnorderedList>
                    ) : (
                      <Text pt="2" fontSize="sm">
                        {t('SuperAdminPage.noUserToDelete')}
                      </Text>
                    )}
                  </Box>
                </Stack>
              </CardBody>
            </Card>
          </ModalBody>
          <ModalFooter>
            <Button onClick={() => setValidationModal(false)} mr={3}>
              {t('SuperAdminPage.cancel')}
            </Button>
            {(data.usersToAdd.length > 0 || data.usersToDelete.length > 0) && (
              <Button onClick={handleUpdateUsers}>
                {t('SuperAdminPage.confirmUpdateUsers')}
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Container>
  )
}
